
import uniqIdMixin from '~/mixins/uniq-id'

export default {
  name: 'BaseList',
  mixins: [
    uniqIdMixin,
  ],

  props: {
    /**
     * Array list to display
     */
    list: {
      type: [Object, Array],
      required: true,
    },

    /**
     * Is ordered list
     */
    isOrdered: {
      type: Boolean,
      default: false,
    },
  },
}


import { createNamespacedHelpers } from 'vuex'
import storeCode from '~/mixins/storeCode'
import configTypes from '~/store/storeConfig/types'

const {
  mapGetters: mapGettersConfig,
} = createNamespacedHelpers('storeConfig')

const {
  mapState: mapStateConfig,
} = createNamespacedHelpers('storeConfig')

export default {
  name: 'TheFooter',

  mixins: [
    storeCode,
  ],

  computed: {
    ...mapStateConfig(['storeConfig', 'footer']),

    ...mapGettersConfig({
      isMegaMenuAvailable: configTypes.IS_MEGAMENU_AVAILABLE,
    }),

    productsMenu () {
      return this.footer[0]?.elements
    },

    meetUsMenu () {
      return this.footer[1]?.element
    },

    isReservationTimerAvailable () {
      return this.$cart.getTimeLeft()
    },
  },
}

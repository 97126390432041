
export default {
  name: 'FooterLinkListWithHeading',
  props: {
    items: {
      type: Array,
      default: () => [],
    },

    heading: {
      type: [String, Object],
      required: true,
    },

    twoColumns: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      isOpen: false,
    }
  },

  computed: {
    isLink () {
      return !!this.heading?.to
    },

    isAccordion () {
      return !this.$css.breakpoints.mdAndUp
    },
  },

  methods: {
    onToggle () {
      this.isOpen = !this.isOpen
    },

    isExternalUrl (url) {
      return url?.startsWith('http') ?? false
    },
  },
}
